import { strings } from "common";
import { TableCellSchema } from "../../interfaces/table.interface";
import { issueStatusList } from "../../constants";

export const cells: TableCellSchema[] = [
  {
    width: "8%",
    text: strings.auid,
    key: "id",
  },
  {
    width: "2%",
    text: strings.po_number,
    key: "purchaseOrderNumber",
  },
  {
    width: "2%",
    text: strings.so_number,
    key: "soNumber",
  },
  {
    width: "1%",
    text: strings.delivery_id,
    key: "deliveryRequestId",
    type: "number",
  },
  {
    width: "1%",
    text: strings.ticket_id,
    key: "issueId",
    type: "number",
  },
  {
    width: "3%",
    text: strings.warehouse,
    key: "warehouseShortCode",
  },
  {
    width: "3%",
    text: strings.warehouseAC,
    key: "warehouseVisionCode",
  },
  {
    width: "6%",
    text: strings.source,
    key: "source",
    selectOptions: [
      { key: "IMS", value: "IMS" },
      { key: "Vintrade", value: "Vintrade" },
      { key: "CultX", value: "CultX" },
      { key: "AppSmith", value: "AppSmith" },
    ],
  },
  {
    width: "6%",
    text: strings.supplier,
    key: "supplierName",
  },
  {
    width: "6%",
    text: strings.lwin,
    key: "lwin",
    type: "number",
  },
  {
    width: "7%",
    text: strings.wine,
    key: "wineName",
  },
  {
    width: "3.5%",
    text: strings.subset,
    key: "subset",
  },
  {
    width: "4.5%",
    text: strings.vintage,
    key: "vintage",
    type: "number",
  },
  {
    width: "4.2%",
    text: strings.size,
    key: "unitSize",
  },
  {
    width: "5.4%",
    text: strings.rotation,
    key: "rotationNumber",
  },
  {
    width: "4%",
    text: strings.prch_price,
    key: "cwPurchasePrice",
    type: "number",
  },
  {
    width: "4%",
    text: strings.client_prch_price,
    key: "clientPurchasePrice",
    type: "number",
  },
  {
    width: "8%",
    text: strings.relationship,
    key: "linkedToPhysical",
    selectOptions: [
      { key: strings.linked, value: true },
      { key: strings.orphanedAssetRecord, value: false },
    ],
  },
  {
    width: "7.2%",
    text: strings.so_date,
    key: "soCreatedDate",
    disableInput: true,
  },
  {
    width: "7.2%",
    text: strings.purchase_date,
    key: "purchaseDate",
    disableInput: true,
  },
  {
    width: "6.7%",
    text: strings.arrival_date,
    key: "expectedDeliveryDate",
    disableInput: true,
  },
  {
    width: "6.7%",
    text: strings.landing_date,
    key: "landingDate",
    disableInput: true,
  },
  {
    width: "6.7%",
    text: strings.departed_date,
    key: "departedDate",
    disableInput: true,
  },
  {
    width: "6.72%",
    text: strings.updated_date,
    key: "updatedAt",
    disableInput: true,
  },
  {
    width: "6.72%",
    text: strings.current_owner,
    key: "customerName",
  },
  {
    width: "5.76%",
    text: strings.status,
    key: "status",
    disableInput: true,
  },
  {
    width: "4%",
    text: strings.ticketStatus,
    key: "issueStatus",
    selectOptions: [
      { key: issueStatusList.OPEN, value: strings.open.toLowerCase() },
      { key: issueStatusList.CLOSED, value: strings.closed.toLowerCase() },
      { key: issueStatusList.PENDING, value: strings.pending.toLowerCase() },
    ],
  },
  {
    width: "8%",
    text: strings.asset_record,
    key: "assetUnitId",
  },
  {
    width: "8%",
    text: strings.physical_record,
    key: "physicalCaseId",
  },
  {
    width: "4%",
    text: strings.stock_id,
    key: "stockId",
    type: "number",
  },
];

export const emptyCells = {
  expandableCell: {
    width: "2.5%",
  },
  firstCell: {
    width: "1.6%",
  },
  lastCell: {
    width: "4%",
  },
};

export const orphanedTabcells: TableCellSchema[] = [
  {
    width: "8%",
    text: strings.pcid,
    key: "id",
  },
  {
    width: "2%",
    text: strings.po_number,
    key: "purchaseOrderNumber",
  },
  {
    width: "1%",
    text: strings.ticket_id,
    key: "issueId",
    type: "number",
  },
  {
    width: "3%",
    text: strings.warehouse,
    key: "warehouseShortCode",
  },
  {
    width: "3%",
    text: strings.warehouseAC,
    key: "fkCustomer",
  },
  {
    width: "6%",
    text: strings.supplier,
    key: "supplierName",
  },
  {
    width: "6%",
    text: strings.lwin,
    key: "lwin18",
    type: "number",
  },
  {
    width: "7%",
    text: strings.wine,
    key: "wineName",
  },
  {
    width: "6%",
    text: strings.status,
    key: "status",
  },
  {
    width: "4.5%",
    text: strings.vintage,
    key: "vintage",
    type: "number",
  },
  {
    width: "4.2%",
    text: strings.size,
    key: "unitSize",
    disableInput: true,
  },
  {
    width: "5.4%",
    text: strings.rotation,
    key: "rotationNumber",
  },
  {
    width: "6.7%",
    text: strings.landing_date,
    key: "landingDate",
    disableInput: true,
  },
  {
    width: "4%",
    text: strings.ticketStatus,
    key: "issueStatus",
    selectOptions: [
      { key: issueStatusList.OPEN, value: strings.open.toLowerCase() },
      { key: issueStatusList.CLOSED, value: strings.closed.toLowerCase() },
      { key: issueStatusList.PENDING, value: strings.pending.toLowerCase() },
    ],
  },
];
