import { strings } from "common";
import { TableCellSchema } from "../../interfaces/table.interface";

export const cells: TableCellSchema[] = [
  {
    width: "7%",
    text: strings.fk_customer,
    key: "fkCustomer",
  },
  {
    width: "7%",
    text: strings.deliveryId,
    key: "deliveryRequestId",
    type: "number",
  },
  {
    width: "7%",
    text: strings.current_owner,
    key: "currentOwner",
    type: "string",
  },
  {
    width: "7%",
    text: strings.po_reference,
    key: "customerPORef",
    disableInput: true,
  },
  {
    width: "7%",
    text: strings.bond_type,
    key: "bondType",
  },
  {
    width: "7%",
    text: strings.document_reference,
    key: "documentRef",
  },
  {
    width: "7%",
    text: strings.dp_account_reference,
    key: "dpAccountRef",
  },
  {
    width: "7%",
    text: strings.order_date,
    key: "orderDate",
    datePicker: true,
  },
  {
    width: "7%",
    text: strings.fk_sales_order_type,
    key: "fkSalesOrderType",
    type: "number",
  },
  {
    width: "7%",
    text: strings.site_code,
    key: "siteCode",
  },
  {
    width: "7%",
    text: strings.is_booking_required,
    key: "isBookingRequired",
    selectOptions: [
      { key: strings.yes, value: true },
      { key: strings.no, value: false },
    ],
  },
  {
    width: "8%",
    text: strings.submittedAt,
    key: "createdAt",
    disableInput: true,
  },
  {
    width: "5%",
    text: strings.status,
    key: "status",
    disableInput: true,
  },
];

export const emptyCells = {
  expandableCell: {
    width: "2.5%",
  },
  firstCell: {
    width: "1.6%",
  },
  lastCell: {
    width: "4%",
  },
};
